// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'accueil';
@import 'samir-step-sizard';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
