.splide__arrow--next {right: 0}
.splide__arrow--prev {left: 0}

.splide__slide__container {
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    border-radius: 25px;
    overflow: hidden;
    margin: 10px;
}
.splide__slide__description {
    margin: 10px;
    padding-top: 15px;
    text-align: center;
}

.splide {
    padding: 2rem;
}
@media (max-width: 600px) {
    .splide { padding: 0.5rem }
}

.main-header {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);

    * {
        margin-bottom: 0 !important;
    }

    @media (max-width: 390px) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        align-content: center;
        text-align: center;
        h3 {
            margin-bottom: 10px !important;
        }
    }
}

.cta {
    text-align: center;
    padding: 2rem;

    a {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 11px 37px;
        background: $teal;
        color: white;
        box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    }
}

////////////////////////

#description {
    // text-align: center;
    font-size: 1rem;
    padding: 2rem;
}

#paiement {
    padding: 2rem;

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color: $teal;
    }

    .nav-pills .nav-link {
        font-size: 1rem;
        padding: 15px 25px;
        line-height: 1.2rem;
    }

    #pills-tab {
        justify-content: center;
    }




}
