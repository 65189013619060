.samir-step-wizard {
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    padding: 5px;
    display: flex;

    p {
        margin: 0;
    }

    .ssw__item {
        flex: 1;
        text-align: center;
        padding: 3px;

        & > div {
            border: 1px solid #e8e8e8;
            border-radius: 12px;
            background-color: #e9e9e9;
            padding: 10px;
        }

        &.active > div {
            background-color: teal;
            color: white;
        }
    }
}
